import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"

const Page = ({data}) => (
  <Layout>
    <div className="mb-8">
      <Seo title={data.wpPage.title} />
      <h1>{data.wpPage.title}</h1>
      <div className="wordpressContent text-default" dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
    </div>
  </Layout>
)

export const query = graphql`
  fragment DefaultPageFragment on WpPage {
    title
    content
  }
  query PageQuery($id : String) {
    wpPage(id: {eq: $id}) {
      ...DefaultPageFragment
    }
  } 
`

export default Page